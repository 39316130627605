import { createSelector } from "reselect";

import { RootState } from "../../store";

const getSupportContents = createSelector(
  (state: RootState) => state.support.GET_DETAIL?.data,
  (contents) => contents
);

export default { getSupportContents };
