import { combineReducers } from "redux";
import { all } from "redux-saga/effects";

import { reducer as fileReducer, saga as fileSaga } from "./file/slice";

const reducer = combineReducers({
  file: fileReducer,
});

function* saga() {
  yield all([fileSaga()]);
}

export { reducer, saga };
