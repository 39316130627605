import produce from "immer";

import {
  CHECK_REGISTERED_EMAIL_RES,
  FindPasswordResponse,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/auth";
import { ApiResponseState } from "@sellernote/_shared/src/types/common/common";
import { apiResponseInitialState } from "@sellernote/_shared/src/utils/common/redux";

import { actionType } from "./actions";

export interface AuthState {
  userId?: number;
  findPassword: Partial<ApiResponseState<string | FindPasswordResponse>>;
  checkRegisteredEmail: Partial<ApiResponseState<CHECK_REGISTERED_EMAIL_RES>>;
}

// Reducer
const initialState: Partial<AuthState> = {};
export const reducer = (state = initialState, action: any) => {
  return produce(state, (draft: Partial<AuthState>) => {
    switch (action.type) {
      case actionType.INIT: {
        draft = initialState;
        break;
      }

      case actionType.INIT_FIND_PASSWORD: {
        draft.findPassword = apiResponseInitialState;
        break;
      }
      case actionType.FIND_PASSWORD_SUCCESS: {
        draft.findPassword = {
          status: "SUCCESS",
        };
        break;
      }
      case actionType.FIND_PASSWORD_FAILURE: {
        draft.findPassword = {
          status: "FAILURE",
          failureInfo: action.payload,
        };
        break;
      }

      case actionType.INIT_CHECK_REGISTERD_EMAIL: {
        draft.checkRegisteredEmail = apiResponseInitialState;
        break;
      }
      case actionType.CHECK_REGISTERD_EMAIL_SUCCESS: {
        draft.checkRegisteredEmail = {
          status: "SUCCESS",
          data: action.payload,
        };
        break;
      }
      case actionType.CHECK_REGISTERD_EMAIL_FAILURE: {
        draft.checkRegisteredEmail = {
          status: "FAILURE",
          failureInfo: action.payload,
        };
        break;
      }
    }
  });
};
