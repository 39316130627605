import { createSlice } from "@reduxjs/toolkit";
import { takeLatest } from "redux-saga/effects";

import { createReducerWithSagaBundleForShipDa } from "@sellernote/_shared/src/services/request";
import { ApiResponseState } from "@sellernote/_shared/src/types/common/common";
import { apiResponseInitialState } from "@sellernote/_shared/src/utils/common/redux";

import { loadingActions } from "../loading";

export interface SupportState {
  GET_LIST: Partial<ApiResponseState<any>>;
  GET_HOW_TO_LIST: Partial<ApiResponseState<any>>;
  GET_TERMS_LIST: Partial<ApiResponseState<any>>;
  GET_FAQ_LIST: Partial<ApiResponseState<any>>;
  GET_DETAIL: Partial<ApiResponseState<any>>;
  GET_SEARCH_LIST: Partial<ApiResponseState<any>>;
  sample?: any;
}

const initialState: SupportState = {
  GET_LIST: apiResponseInitialState,
  GET_HOW_TO_LIST: apiResponseInitialState,
  GET_TERMS_LIST: apiResponseInitialState,
  GET_FAQ_LIST: apiResponseInitialState,
  GET_DETAIL: apiResponseInitialState,
  GET_SEARCH_LIST: apiResponseInitialState,
};

const SLICE_NAME = "support";

const GET_LIST = createReducerWithSagaBundleForShipDa<
  "GET_LIST",
  any,
  any,
  SupportState
>({
  sliceName: SLICE_NAME,
  actionTypeKey: "GET_LIST",
  getRequestOptions: (params) => {
    return {
      method: "get",
      path: "/article",
      apiType: "Contents",
      params,
    };
  },
  loadingActions,
});

const GET_HOW_TO_LIST = createReducerWithSagaBundleForShipDa<
  "GET_HOW_TO_LIST",
  any,
  any,
  SupportState
>({
  sliceName: SLICE_NAME,
  actionTypeKey: "GET_HOW_TO_LIST",
  getRequestOptions: (params) => {
    return {
      method: "get",
      path: "/article",
      apiType: "Contents",
      params: { articleType: "howto", ...params },
    };
  },
  loadingActions,
});

const GET_TERMS_LIST = createReducerWithSagaBundleForShipDa<
  "GET_TERMS_LIST",
  any,
  any,
  SupportState
>({
  sliceName: SLICE_NAME,
  actionTypeKey: "GET_TERMS_LIST",
  getRequestOptions: (params) => {
    return {
      method: "get",
      path: "/article",
      apiType: "Contents",
      params: { articleType: "terms", ...params },
    };
  },
  loadingActions,
});

const GET_FAQ_LIST = createReducerWithSagaBundleForShipDa<
  "GET_FAQ_LIST",
  any,
  any,
  SupportState
>({
  sliceName: SLICE_NAME,
  actionTypeKey: "GET_FAQ_LIST",
  getRequestOptions: (params) => {
    return {
      method: "get",
      path: "/article",
      apiType: "Contents",
      params: { articleType: "faq", ...params },
    };
  },
  loadingActions,
});

const GET_DETAIL = createReducerWithSagaBundleForShipDa<
  "GET_DETAIL",
  any,
  any,
  SupportState
>({
  sliceName: SLICE_NAME,
  actionTypeKey: "GET_DETAIL",
  getRequestOptions: (payload) => {
    return {
      method: "get",
      path: `/article/${payload.id}`,
      apiType: "Contents",
    };
  },
  loadingActions,
});

const GET_SEARCH_LIST = createReducerWithSagaBundleForShipDa<
  "GET_SEARCH_LIST",
  any,
  any,
  SupportState
>({
  sliceName: SLICE_NAME,
  actionTypeKey: "GET_SEARCH_LIST",
  getRequestOptions: (params) => {
    return {
      method: "get",
      path: "/article/search",
      apiType: "Contents",
      params,
    };
  },
  loadingActions,
});

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    ...GET_LIST.reducerBundle,
    ...GET_HOW_TO_LIST.reducerBundle,
    ...GET_TERMS_LIST.reducerBundle,
    ...GET_FAQ_LIST.reducerBundle,
    ...GET_DETAIL.reducerBundle,
    ...GET_SEARCH_LIST.reducerBundle,
  },
});

function* saga() {
  yield takeLatest(slice.actions.GET_LIST.type, GET_LIST.saga);
  yield takeLatest(slice.actions.GET_HOW_TO_LIST.type, GET_HOW_TO_LIST.saga);
  yield takeLatest(slice.actions.GET_TERMS_LIST.type, GET_TERMS_LIST.saga);
  yield takeLatest(slice.actions.GET_FAQ_LIST.type, GET_FAQ_LIST.saga);
  yield takeLatest(slice.actions.GET_DETAIL.type, GET_DETAIL.saga);
  yield takeLatest(slice.actions.GET_SEARCH_LIST.type, GET_SEARCH_LIST.saga);
}

export default slice;

export { saga };
