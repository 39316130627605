import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { takeLatest } from "redux-saga/effects";

import {
  SAMPLE_GET_LIST_REQ,
  SAMPLE_GET_LIST_RES,
} from "@sellernote/_shared/src/api-interfaces/boful-api/_sample";
import { createReducerWithSagaBundleForShipDa } from "@sellernote/_shared/src/services/request";
import { ApiResponseState } from "@sellernote/_shared/src/types/common/common";
import { apiResponseInitialState } from "@sellernote/_shared/src/utils/common/redux";

import { loadingActions } from "../loading";

export interface SampleState {
  SAMPLE_GET_LIST: Partial<ApiResponseState<SAMPLE_GET_LIST_RES>>;
  sample?: any;
}

const initialState: SampleState = {
  SAMPLE_GET_LIST: apiResponseInitialState,
};

const SLICE_NAME = "sample";

const SAMPLE_GET_LIST = createReducerWithSagaBundleForShipDa<
  "SAMPLE_GET_LIST",
  SAMPLE_GET_LIST_REQ,
  SAMPLE_GET_LIST_RES,
  SampleState
>({
  sliceName: SLICE_NAME,
  actionTypeKey: "SAMPLE_GET_LIST",
  getRequestOptions: (payload) => {
    return {
      method: "get",
      path: "/info/ports",
      params: payload,
    };
  },
  loadingActions,
});

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    ...SAMPLE_GET_LIST.reducerBundle,
    SAMPLE_UPDATE_STATE: {
      prepare: (key: number, value: string) => {
        return {
          payload: { key, value },
        };
      },
      reducer: (state, action: PayloadAction<Record<string, unknown>>) => {
        state.sample = "something";
      },
    },
  },
});

function* saga() {
  yield takeLatest(slice.actions.SAMPLE_GET_LIST.type, SAMPLE_GET_LIST.saga);
}

export default slice;

export { saga };
