import selectors from "./selectors";
import slice, { saga } from "./slice";

const fulfillmentReducer = slice.reducer;
const fulfillmentActions = slice.actions;
const fulfillmentSaga = saga;
const fulfillmentSelectors = selectors;

export {
  fulfillmentReducer,
  fulfillmentActions,
  fulfillmentSaga,
  fulfillmentSelectors,
};
