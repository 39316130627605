import { createSlice } from "@reduxjs/toolkit";
import { takeLatest } from "redux-saga/effects";

import {
  BIND_FILE_AFTER_UPLOAD_REQ,
  BIND_FILE_AFTER_UPLOAD_RES,
  GET_PRESIGNED_URL_RES,
} from "@sellernote/_shared/src/api-interfaces/boful-api/file";
import { actions as loadingActions } from "@sellernote/_shared/src/commonReduxActions/loadingActions";
import { createReducerWithSagaBundleForShipDa } from "@sellernote/_shared/src/services/request";
import { ApiResponseState } from "@sellernote/_shared/src/types/common/common";
import { apiResponseInitialState } from "@sellernote/_shared/src/utils/common/redux";

export interface SliceState {
  GET_PRESIGNED_URL?: ApiResponseState<GET_PRESIGNED_URL_RES>;
  BIND_FILE_AFTER_UPLOAD?: Partial<
    ApiResponseState<BIND_FILE_AFTER_UPLOAD_RES>
  >;
}

const SLICE_NAME = "features/common/file";

const initialState: SliceState = {
  BIND_FILE_AFTER_UPLOAD: apiResponseInitialState,
};

const GET_PRESIGNED_URL = createReducerWithSagaBundleForShipDa<
  "GET_PRESIGNED_URL",
  any,
  GET_PRESIGNED_URL_RES,
  SliceState
>({
  sliceName: SLICE_NAME,
  actionTypeKey: "GET_PRESIGNED_URL",
  getRequestOptions: (payload) => {
    const { apiType, uploadFilesInfo } = payload;

    return {
      method: "post",
      ...(apiType === "boful"
        ? {
            path: "/file/upload",
            apiType: "BofulDefault",
            data: uploadFilesInfo,
          }
        : {
            path:
              apiType === "nonMembers"
                ? "/file/upload/non-member"
                : "/file/upload",
            apiType: "ShipdaDefaultNew",
            data: {
              presignedUrlOptions: uploadFilesInfo,
              isPublic: false,
            },
          }),
    };
  },
  loadingActions,
});

const BIND_FILE_AFTER_UPLOAD = createReducerWithSagaBundleForShipDa<
  "BIND_FILE_AFTER_UPLOAD",
  BIND_FILE_AFTER_UPLOAD_REQ,
  BIND_FILE_AFTER_UPLOAD_RES,
  SliceState
>({
  sliceName: SLICE_NAME,
  actionTypeKey: "BIND_FILE_AFTER_UPLOAD",
  getRequestOptions: (payload) => {
    const { apiType, data } = payload;

    return {
      method: "put",
      path:
        apiType === "nonMembers" ? "/file/binding/non-member" : "/file/binding",
      apiType: apiType === "boful" ? "BofulDefault" : "ShipdaDefaultNew",
      data: apiType === "boful" ? data : { bindList: data },
    };
  },
  loadingActions,
});

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    ...GET_PRESIGNED_URL.reducerBundle,
    ...BIND_FILE_AFTER_UPLOAD.reducerBundle,
  },
});

function* saga() {
  yield takeLatest(
    slice.actions.GET_PRESIGNED_URL.type,
    GET_PRESIGNED_URL.saga
  );
  yield takeLatest(
    slice.actions.BIND_FILE_AFTER_UPLOAD.type,
    BIND_FILE_AFTER_UPLOAD.saga
  );
}

export default slice;

const actions = slice.actions;

const reducer = slice.reducer;

const selectors = {};

export { saga, actions, selectors, reducer };
