import { DownloadTargetType } from "@sellernote/_shared/src/types/common/download";
import { LoadingWithKey } from "@sellernote/_shared/src/types/common/loading";

import { RootState } from "../../modules";

import { actionType } from "./actions";

// Selectors
export const selectors = {
  getIsLoadingGetLink: (
    state: RootState,
    targetType: DownloadTargetType | string
  ) => {
    if (!state.loading[actionType.GET_LINK]) {
      return;
    }

    return (state.loading[actionType.GET_LINK] as LoadingWithKey)[targetType];
  },
  getLinkInfo: (state: RootState, targetType: DownloadTargetType | string) => {
    return state.download[targetType];
  },
};
