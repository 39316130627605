import produce from "immer";

import { actionType } from "@sellernote/_shared/src/commonReduxActions/loadingActions";
import {
  LoadingState,
  LoadingWithKey,
} from "@sellernote/_shared/src/types/common/loading";

// Reducer
const initialState: LoadingState = {};
export const reducer = function (state = initialState, action: any) {
  return produce(state, (draft: LoadingState) => {
    switch (action.type) {
      case actionType.START_LOADING: {
        if (hasKey(action.payload.key)) {
          if (!draft[action.payload.requestType]) {
            draft[action.payload.requestType] = {};
          }

          (draft[action.payload.requestType] as LoadingWithKey)[
            action.payload.key
          ] = true;
        } else {
          draft[action.payload.requestType] = true;
        }

        break;
      }

      case actionType.FINISH_LOADING: {
        if (hasKey(action.payload.key)) {
          if (!draft[action.payload.requestType]) {
            draft[action.payload.requestType] = {};
          }

          (draft[action.payload.requestType] as LoadingWithKey)[
            action.payload.key
          ] = false;
        } else {
          draft[action.payload.requestType] = false;
        }

        break;
      }

      default:
        break;
    }
  });
};
function hasKey(key?: number) {
  return Boolean(key || typeof key === "number");
}
