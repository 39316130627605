import * as Sentry from "@sentry/nextjs";

import { APP_ENV, APP_NAME } from "../../constants";

/**
 * Next.js 의 Sentry초기화에 공통으로 사용되는 옵션들
 */
const commonInitOptions: Sentry.NodeOptions = {
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  initialScope: {
    // custom tag
    tags: {
      errorType: "FE",
      appName: APP_NAME,
    },
  },
  release: process.env.NEXT_PUBLIC_COMMIT_SHA,
  ignoreErrors: [
    "amplitude is not defined",
    "Cannot read properties of undefined (reading 'cnv')",
  ],
  environment: APP_ENV,
};

type CustomNextJSErrorProp = {
  /** 렌더링중 발생하는 오류중 Sentry로 리포트 되지 않는 것이 있어 직접 전달
   * - 공식문서에서 동일한 이슈에 대한 설명은 보지 못했으나, 리렌더링시 발생하는 Reference Error가 ErrorBoundary 에서 캐치되지 않는 것으로 보임.
   * - <참고>
   *   - 위 Reference Error발생시,
   *     - 처음에는 _error.tsx 에서 정의한 Custom 에러 페이지로 리디렉션되지만 에러객체 정보가 없는 상태에서 오므로 이렇게 수동으로 에러객체를 전달해 줌
   *     - 브라우저 새로고침을 하면, _error.tsx가 아닌 serverless function 단계에서 에러가 발생함 (이 경우도 Sentry로 리포트 됨)
   **/
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  err: any;
};

type CustomAppProps<AppProps> = CustomNextJSErrorProp & AppProps;

type CustomErrorPageProps<ErrorProps> = CustomNextJSErrorProp & ErrorProps;

export { commonInitOptions };

export type { CustomAppProps, CustomErrorPageProps };
