import { createSelector } from "reselect";

import { RootState } from "../../store";

const sampleGetCountries = createSelector(
  (state: RootState) => state.common,
  (countries) => []
);

export default { sampleGetCountries };
