import { combineReducers } from "redux";
import { all } from "redux-saga/effects";

import {
  reducer as commonReducer,
  saga as commonSaga,
} from "./common/combinedSlice";

const reducer = combineReducers({
  common: commonReducer,
});

function* saga() {
  yield all([commonSaga()]);
}

export { reducer, saga };
