import { takeLatest } from "redux-saga/effects";

import { sendRequest } from "@sellernote/_shared/src/services/request";
import { AnyRequestPayload } from "@sellernote/_shared/src/types/common/common";
import { createRequestSaga } from "@sellernote/shipda-kr/src/services/legacyRequest";

// Action Type
const prefix = "common/";
export const actionType = {
  INIT: `${prefix}INIT`,

  /**
   * 어떤 요청에서든 사용할 수 있는 action type (이메일 버튼에 의한 요청을 처리하는 데 쓰임)
   */
  ANY_REQUEST: `${prefix}ANY_REQUEST`,
  ANY_REQUEST_SUCCESS: `${prefix}ANY_REQUEST_SUCCESS`,
  ANY_REQUEST_FAILURE: `${prefix}ANY_REQUEST_FAILURE`,
  INIT_ANY_REQUEST: `${prefix}INIT_ANY_REQUEST`,
};

// Actions
export const actions = {
  init: () => {
    return { type: actionType.INIT };
  },

  initAnyRequest: () => {
    return { type: actionType.INIT_ANY_REQUEST };
  },
  anyRequest: (payload: AnyRequestPayload) => {
    return { type: actionType.ANY_REQUEST, payload };
  },
};

// Sagas
const sagas = {
  anyRequestSaga: createRequestSaga(
    actionType.ANY_REQUEST,
    (payload: AnyRequestPayload) => {
      const requestPayload: {
        params?: Record<string, unknown>;
        data?: Record<string, unknown>;
      } = {};

      if (payload.requestPayload) {
        switch (payload.httpMethod) {
          case "get": {
            requestPayload.params = {
              ...payload.requestPayload,
            };
            break;
          }
          default: {
            requestPayload.data = {
              ...payload.requestPayload,
            };
            break;
          }
        }
      }

      return sendRequest({
        method: payload.httpMethod,
        path: payload.requestPath,
        ...requestPayload,
        apiType: "ShipdaDefaultNew",
      });
    }
  ),
};
export function* commonSaga() {
  yield takeLatest(actionType.ANY_REQUEST, sagas.anyRequestSaga);
}
