import { takeLatest } from "redux-saga/effects";

import { sendRequest } from "@sellernote/_shared/src/services/request";
import { createRequestSaga } from "@sellernote/shipda-kr/src/services/legacyRequest";

// Action Type
const prefix = "upload/";
export const actionType = {
  INIT: `${prefix}INIT`,

  DELETE_FILE: `${prefix}DELETE_FILE`,
  DELETE_FILE_SUCCESS: `${prefix}DELETE_FILE_SUCCESS`,
  DELETE_FILE_FAILURE: `${prefix}DELETE_FILE_FAILURE`,
};

// Actions
export const actions = {
  init: () => {
    return { type: actionType.INIT };
  },
  deleteFile: (payload: any) => {
    return { type: actionType.DELETE_FILE, payload };
  },
};

// Sagas
const sagas = {
  deleteFileSaga: createRequestSaga(actionType.DELETE_FILE, (payload: any) => {
    return sendRequest({
      method: "delete",
      path: "file/delete",
      data: payload,
      apiType: "ShipdaDefaultNew",
    });
  }),
};

export function* uploadSaga() {
  yield takeLatest(actionType.DELETE_FILE, sagas.deleteFileSaga);
}
