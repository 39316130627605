import produce from "immer";

import { ApiResponseState } from "@sellernote/_shared/src/types/common/common";
import { apiResponseInitialState } from "@sellernote/_shared/src/utils/common/redux";

import { actionType } from "./actions";

export interface CommonState {
  anyRequest: Partial<ApiResponseState<any>>;
}

// Reducer
const initialState: Partial<CommonState> = {};

export const reducer = (state = initialState, action: any) => {
  return produce(state, (draft: Partial<CommonState>) => {
    switch (action.type) {
      case actionType.INIT: {
        draft = initialState;
        break;
      }

      case actionType.INIT_ANY_REQUEST: {
        draft.anyRequest = apiResponseInitialState;
        break;
      }
      case actionType.ANY_REQUEST_SUCCESS: {
        draft.anyRequest = {
          data: action.payload,
          status: "SUCCESS",
        };
        break;
      }
      case actionType.ANY_REQUEST_FAILURE: {
        draft.anyRequest = {
          status: "FAILURE",
          failureInfo: action.payload,
        };
        break;
      }
    }
  });
};
