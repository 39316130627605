export class ShipdaCurrentLanguage {
  private static instance: ShipdaCurrentLanguage;
  private static readonly DEFAULT_LANGUAGE = "ko";
  private currentLanguage: string = ShipdaCurrentLanguage.DEFAULT_LANGUAGE;
  private static initialized = false;

  private constructor() {}

  private static getInstance(): ShipdaCurrentLanguage {
    if (!this.instance) {
      this.instance = new ShipdaCurrentLanguage();
    }

    return this.instance;
  }

  public static init(
    language: string | undefined = ShipdaCurrentLanguage.DEFAULT_LANGUAGE
  ) {
    if (!this.initialized) {
      this.getInstance().currentLanguage = language;
      this.initialized = true;
    }
  }

  public static get currentLanguage(): string {
    return this.getInstance().currentLanguage;
  }

  public static set currentLanguage(language: string) {
    this.getInstance().currentLanguage = language;
  }
}
