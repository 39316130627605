import { TypedUseSelectorHook, useSelector } from "react-redux";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { AppContext } from "next/app";
import {
  Context,
  createWrapper,
  GetServerSidePropsContext,
} from "next-redux-wrapper";
import { AnyAction, Reducer, Store } from "redux";
import createSagaMiddleware, { END, Task } from "redux-saga";

import {
  IS_UNDER_LOCAL_DEVELOPMENT,
  IS_UNDER_PRODUCTION,
} from "@sellernote/_shared/src/constants";

import { RootState as RootStateOnType } from "../src/modules";
import { rootReducer, rootSaga } from "./modules";

export interface GetServerSidePropsContextWithSagaStore
  extends GetServerSidePropsContext {
  store: SagaStore;
  locale?: string;
}

export interface SagaStore extends Store {
  sagaTask?: Task;
}

const makeStore = (context: Context) => {
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    reducer: rootReducer as Reducer<RootStateOnType, AnyAction>,
    middleware: [
      ...getDefaultMiddleware({
        serializableCheck: {
          // Ignore these field paths in all actions
          ignoredActionPaths: [
            "payload._postSuccessActionCreator",
            "payload._postSuccessCallback",
            "payload._postFailureCallback",
          ],
        },
      }),
      sagaMiddleware,
    ],
    devTools: IS_UNDER_LOCAL_DEVELOPMENT,
  });

  (store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

export const wrapper = createWrapper<any>(makeStore, {
  serializeState: (state) => {
    // state를 그대로 return하면 undefined을 직렬화할수 없다는 오류발생 - state를 지우고 보내는 과정을 추가하여 해결함
    const sanitized = JSON.parse(JSON.stringify(state));

    return {
      ...sanitized,
    };
  },
  debug: !IS_UNDER_PRODUCTION,
});

/**
 * _app.tsx파일의 getInitialProps에 대입되는 함수를 생성
 */
export const makeGetInitialPropsFunctionOnAppRoot =
  () =>
  async ({ Component, ctx }: AppContext) => {
    // 1. Wait for all page actions to dispatch
    const pageProps = {
      ...(Component.getInitialProps
        ? await Component.getInitialProps(ctx)
        : {}),
    };

    // 2. Stop the saga if on server
    if (ctx.req) {
      (ctx as any).store.dispatch(END);
      await ((ctx as any).store as SagaStore).sagaTask!.toPromise();
    }

    // 3. Return props
    return {
      pageProps,
    };
  };

export type RootState = ReturnType<() => RootStateOnType>;
export type AppDispatch = ReturnType<any>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
// export const useAppDispatch = () => useDispatch<AppDispatch>(); // guide에서는 쓰라고 하는데 왜 필요한지 모르겠음
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
